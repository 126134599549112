import * as React from "react";
import { useTranslation } from "react-i18next";
import { StandardPageTemplate } from "../../templates/StandardPageTemplate/StandardPageTemplate";
import { GalahRoute } from "../../../services/Constants/GalahRoute";

export const AboutPage: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const description = t("homePage.description");
    const title = t("homePage.title");
    return (
        <StandardPageTemplate title={title} description={description} canonical={GalahRoute.About}>
            Some stuff about matt
        </StandardPageTemplate>
    );
};
